/* html, body, #root {
    height: 100vh;
  } */
@tailwind base;
@tailwind components;
@tailwind utilities;
  .footer {
    padding: 23px;
    position: absolute;
    width: 95%;
    
  }